import { Box, Stack, Typography } from "@mui/material";
import logo from "../../../assets/images/logo.jpg";
import React from "react";
import Styles from "./logoContainer.module.css";

const LogoContainer = ({ textHeader }) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={"10px"}
      className={Styles.stepHeader}
    >
      <Box className={Styles.logoCont}>
        <img src={logo} alt="" />
      </Box>
      <Typography className={Styles.header}>{textHeader}</Typography>
    </Stack>
  );
};

export default LogoContainer;
