import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import Slider from "../slider/pages/Slider";
import "reactflow/dist/style.css";
import CustomNode from "../customNode/CustomNode";
import NavBar from "../navBar/NavBar";
import { useParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

import axios from "axios";
import { Box } from "@mui/material";
import { baseURL } from "../../../axiosApi/axiosApi.js";
import BackModal from "../backModal/BackModal.jsx";

const initialNodes = [
  {
    id: "1",
    position: { x: 500, y: 80 },
    data: {
      id: "1",
      label: "Start",
      icon: "URL_OR_IMPORT_ICON_START",
      type: "start",
      end: true,
    },
    type: "customNode",
  },
];

const initialEdges = [];

export default function Design() {
  const params = useParams();
  const accountNumber = params.accNum;
  const [allErrors, setAllErrors] = useState([]);
  const [savingLoading, setSavingLoading] = useState(false);
  const [data, setData] = useState();
  const { chatId } = useParams();
  const [dataisLoading, setDataIsLoading] = useState(false);

  const getFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? storedValue : null;
  };

  useEffect(() => {
    // const shouldRunEffect = getFromLocalStorage("newChatIdDesign");
    // console.log("hwa ana d5lt ?");
    setDataIsLoading(true);
    let newChat = chatId;
    if (newChat === undefined) {
      newChat = getFromLocalStorage("newChatIdDesign");

      if (newChat !== null) {
        // Modify the URL when the component mounts
        const newURL = `#/design/${accountNumber}/${newChat}`;

        window.history.replaceState({ path: `${newURL}` }, "", `${newURL}`);
      } else {
        setDataIsLoading(false);
      }
    } else {
      newChat = chatId;
    }

    // console.log("waryny kdh al final chat id", newChat);
    const fetchIdAndMessage = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/chatbot/KBot/GetChatsDesignById`,
          {
            params: { chatid: `{"ChatId":"${newChat}"}` },
          }
        );
        if (response.status === 200) {
          setDataIsLoading(false);
          const jsonString = response.data[0].jsonData;
          // console.log("jsonString", jsonString);
          const jsonObject = JSON.parse(jsonString);
          setData(jsonObject);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchIdAndMessage();
  }, [accountNumber, chatId]);

  const [render, setRerender] = useState(false);

  // useEffect(() => {
  //   if (dataisLoading) {
  //     // If savingLoading is true, set it to false after 1 second
  //     const timeoutId = setTimeout(() => {
  //       setDataIsLoading(false);
  //     }, 3000);

  //     // Cleanup the timeout to avoid memory leaks
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [dataisLoading]);

  useEffect(() => {
    if (savingLoading) {
      // If savingLoading is true, set it to false after 1 second
      const timeoutId = setTimeout(() => {
        setSavingLoading(false);
      }, 1000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [savingLoading]);

  function reRender() {
    setSavingLoading(true); // Set savingLoading to true
    setRerender(!render);
  }

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const [buttonPosition, setButtonPosition] = useState({
    x: 463,
    y: 176,
  });
  const [chatName, setChatName] = useState("My First App");
  const [nodeCounters, setNodeCounters] = useState({});

  const savetheChatName = (val) => {
    setChatName(val);
  };

  useEffect(() => {
    if (nodes && nodes.length > 0) {
      const labels = nodes.map((obj) => obj.data.label);
      const uniqueLabels = new Set(labels);

      const newErrors = [];
      labels.forEach((label, index, arr) => {
        if (arr.indexOf(label) !== index) {
          if (
            nodes[index].data.type !== "Button" &&
            nodes[index].data.type !== "Card"
          ) {
            newErrors.push({
              id: nodes[index].data.id,
              title: label,
              subject: `The action block title is not unique`,
            });
          }
        }
      });

      // Filter out objects with subject "The action block title is not unique"
      const filteredErrors = allErrors.filter(
        (error) => error.subject !== "The action block title is not unique"
      );

      // Check if there are changes before updating state
      if (
        JSON.stringify([...filteredErrors, ...newErrors]) !==
        JSON.stringify(allErrors)
      ) {
        setAllErrors([...filteredErrors, ...newErrors]);
      }
    }
  }, [nodes, allErrors]);

  useEffect(() => {
    if (nodes && nodes.length > 0) {
      const imageCarouselNodes = nodes.filter(
        (node) => node.data.type === "Image Carousel"
      );
      // console.log(imageCarouselNodes);

      setAllErrors((prevErrors) =>
        prevErrors.filter(
          (error) => error.subject === "You need at least to upload one file"
        )
      );

      imageCarouselNodes.forEach((node) => {
        if (node.imageCarousel.length === 0) {
          const newEmptyTextErrors = {
            id: node.data.id,
            title: node.data.label === "" ? "Untitled" : node.data.label,
            subject: "You need at least to upload one file",
          };

          setAllErrors((prevErrors) => {
            const updatedErrors = prevErrors.filter(
              (error) =>
                !(
                  error.id === node.data.id &&
                  error.subject === "You need at least to upload one file"
                )
            );

            return [...updatedErrors, newEmptyTextErrors];
          });
        } else {
          // Remove the error if allImages is not empty
          setAllErrors((prevErrors) =>
            prevErrors.filter(
              (error) =>
                !(
                  error.id === node.data.id &&
                  error.subject === "You need at least to upload one file"
                )
            )
          );
        }
      });
    }
  }, [nodes, allErrors]);

  useEffect(() => {
    if (nodes && nodes.length > 0) {
      const flowNodes = nodes.filter((node) => node.data.type === "Flow");
      const edgeIds = edges.map((edge) => edge.source); // Extracting IDs from edges source

      setAllErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            error.subject === "Flow component must be followed by a component"
        )
      );

      // Handle errors for flows with invalid IDs
      const invalidFlowIds = flowNodes
        .map((flow) => flow.data.id)
        .filter((id) => !edgeIds.includes(id));
      invalidFlowIds.forEach((id) => {
        const flowNode = flowNodes.find((node) => node.data.id === id);
        if (flowNode) {
          const newError = {
            id: id,
            title: flowNode.data.label || "Untitled",
            subject: "Flow component must be followed by a component",
          };
          setAllErrors((prevErrors) => [...prevErrors, newError]);
        }
      });
    }
  }, [nodes, edges, allErrors]);

  useEffect(() => {
    if (data) {
      setNodes(data?.Task);
      setEdges(data?.PathTask);
      setChatName(data?.ChatName);
      // console.log("testtttt", data?.ChatName);
      setNodeCounters(data?.nodeCounters);
    } else {
      setNodes(initialNodes);
      setEdges(initialEdges);
      setChatName("My First App");
      setNodeCounters({});
    }
  }, [data]);

  const [index, setIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu visibility
  const [isMenuOpenBtn, setIsMenuOpenBtn] = useState(false); // State for menu visibility
  const [selectedNodeType, setSelectedNodeType] = useState(null); // Track selected node type
  const [showSlider, setShowSlider] = useState(false); // Control Slider visibility
  const [selectedId, setSelectedId] = useState("1");
  const [count, setCount] = useState(0);

  const increaseCount = () => setCount(1);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const toggleMenuBtn = () => setIsMenuOpenBtn((prev) => !prev);

  // const [executedConnect, setExecutedConnect] = useState(false);

  // const onConnect = useCallback((params) => {
  //   const sourceNode = nodes.find((node) => node.id === params.source);
  //   if (sourceNode && sourceNode.data.type === "Flow" && !executedConnect) {
  //     setExecutedConnect(true);
  //     setEdges((eds) => addEdge(params, eds));
  //   }
  // }, [nodes, setEdges, executedConnect]);

  const [connectedFlowNodes, setConnectedFlowNodes] = useState([]);

  const onConnect = useCallback(
    (params) => {
      // console.log("params", params);
      const sourceNode = nodes.find((node) => node.id === params.source);
      // console.log("sourceNode", sourceNode);

      // Check if the source node is a Flow and hasn't been connected before
      if (
        sourceNode &&
        sourceNode.data.type === "Flow" &&
        !connectedFlowNodes.includes(sourceNode.id)
      ) {
        setEdges((eds) => addEdge(params, eds));

        // Mark the Flow node as connected
        setConnectedFlowNodes((prevNodes) => [...prevNodes, sourceNode.id]);
      }
    },
    [nodes, setEdges, connectedFlowNodes]
  );

  // add a new node
  const handleAddNode = (nodeType) => {
    // console.log(nodeType);
    // console.log(nodes);
    increaseCount();
    setIsMenuOpen(false); // Close the menu
    setIsMenuOpenBtn(false);
    const previousNode = nodes?.find((node) => node.id === selectedId);
    const newY = previousNode ? previousNode.position.y + 150 : 100; // Calculate the new Y-coordinate
    const newX = previousNode.position.x; // Calculate the new X-coordinate
    let newNodeData = {};

    // Initialize or get the counter for the specific nodeType
    let counter = nodeCounters[nodeType] || 1;

    if (nodeType === "Send message") {
      newNodeData = {
        label: `Send message ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Send message",
        description: "<p>Hi there! My name is…</p>",
        end: true,
      };
    } else if (nodeType === "Buttons") {
      newNodeData = {
        label: `Buttons ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Buttons",
        description: "<p>What would you like to choose?</p>",
        end: false,
      };
    } else if (nodeType === "Forms") {
      newNodeData = {
        label: `Forms ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Forms",
        description: "<p>Please fill in the following details</p>",
        end: true,
      };
    } else if (nodeType === "Calendar") {
      newNodeData = {
        label: `Calendar ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Calendar",
        description: "What is your preferred date?",
        end: true,
      };
    } else if (nodeType === "Image Carousel") {
      newNodeData = {
        label: `Image Carousel ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Image Carousel",
        end: true,
      };
    } else if (nodeType === "Collect Input") {
      newNodeData = {
        label: `Collect Input ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Collect Input",
        description: "<p>What is your…</p>",
        end: true,
      };
    } else if (nodeType === "Carousel") {
      newNodeData = {
        label: `Carousel ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Carousel",
        end: false,
      };
    } else if (nodeType === "Send an email") {
      newNodeData = {
        label: `Send an Email ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Send an email",
        end: true,
      };
    } else if (nodeType === "Branch") {
      newNodeData = {
        label: `Branch ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Branch",
        end: true,
      };
    } else if (nodeType === "Slider") {
      newNodeData = {
        label: `Slider ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Slider",
        description: "What is your preferred value?",
        end: true,
      };
    } else if (nodeType === "File Upload") {
      newNodeData = {
        label: `File Upload ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "File Upload",
        description: "Can you please upload your file?",
        end: true,
      };
    } else if (nodeType === "Delay") {
      newNodeData = {
        label: `Delay ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Delay",
        end: true,
      };
    } else if (nodeType === "Flow") {
      newNodeData = {
        label: `Flow ${counter}`,
        icon: "URL_OR_IMPORT_ICON_START",
        type: "Flow",
        end: true,
      };
    }

    setNodeCounters((prevCounters) => ({
      ...prevCounters,
      [nodeType]: counter + 1,
    }));

    const newNodeId = Date.now().toString();
    const newNode = {
      id: newNodeId, // Generate a unique ID for the new node
      position: { x: newX, y: newY }, // Set the position with the calculated coordinates
      data: {
        ...newNodeData,
        id: newNodeId, // Add newNodeId to the data object
      },
      type: "customNode",
      onClose: { handleSliderClose },
    };

    if (nodeType === "Send message") {
      newNode.items = [
        { type: "text", content: "<p>Hi there! My name is…</p>" },
      ];
      newNode.imageCounter = 1;
    } else if (nodeType === "Forms") {
      newNode.forms = [
        {
          value: "name",
          type: "text",
          optional: true,
        },
        {
          value: "email",
          type: "email",
          optional: true,
        },
        {
          value: "phone",
          type: "tel",
          optional: true,
        },
      ];
    } else if (nodeType === "Calendar") {
      newNode.disabledDays = [7];
      newNode.timeText = "What is your preferred time?";
      newNode.disabledDates = [
        "Tue Jan 23 2021 00:00:00 GMT-0800 (Pacific Standard Time)",
      ];
      newNode.excludedDaysOfWeek = [7];
      newNode.timing = {
        "0": { from: "06:00", to: "12:00" },
        "1": { from: "06:00", to: "12:00" },
        "2": { from: "06:00", to: "12:00" },
        "3": { from: "06:00", to: "12:00" },
        "4": { from: "06:00", to: "12:00" },
        "5": { from: "06:00", to: "12:00" },
        "6": { from: "06:00", to: "12:00" },
      };
      newNode.duration = 60;
      newNode.defaultFrom = "06:00";
      newNode.defaultTo = "12:00";
      newNode.calenderType = "dates";
      newNode.selectedSpecificTimeToDate = "";
      newNode.scheduleOpining = false;
      newNode.calenderOpining = false;
    } else if (nodeType === "Image Carousel") {
      newNode.imageCarousel = [];
      newNode.imageCounter = 1;
    } else if (nodeType === "Buttons") {
      newNode.chidlrenDataIndex = [];
      newNode.childrenPathIndex = [];
      newNode.allButton = [];
      newNode.BtnCounter = 1;
    } else if (nodeType === "Carousel") {
      newNode.chidlrenDataIndex = [];
      newNode.childrenPathIndex = [];
      newNode.cardCounter = 1;
      newNode.options = [
        {
          ButtonschidlrenDataIndex: [],
          ButtonschildrenPathIndex: [],
          cardId: "",
          img: "",
          text: "Card 1",
          description: "<p>Card 1 Description</p>",
          buttons: [{ id: "", value: "option-1", type: "BranchButton" }],
        },
      ];
    } else if (nodeType === "Send an email") {
      newNode.normalEmail = [];
      newNode.ccEmail = [];
      newNode.bccEmail = [];
      newNode.subject = "Lead generated via Niyat";
      newNode.markup =
        "<p>Add what you want to be notified when an email is sent...</p>";
    } else if (nodeType === "Branch") {
    } else if (nodeType === "Slider") {
      newNode.from = 0;
      newNode.to = 10;
      newNode.includeCountStep = false;
      newNode.countStep = 2;
      newNode.unitValue = "";
    } else if (nodeType === "File Upload") {
      newNode.selectedType = [".pdf", ".jpg", ".png"];
      newNode.SelectedSize = 10;
      newNode.SelectedNumber = 1;
    } else if (nodeType === "Delay") {
      newNode.delay = 1;
    }

    const newEdge = {
      id: `e${previousNode.id}-${newNode.id}`,
      source: previousNode.id,
      target: newNode.id,
      animated: true, // dh hna aly by3ml al animated
      // label: "to",
    };
    const newAllNodes = [...nodes, newNode];
    const newAllEdges = [...edges, newEdge];

    const updatedNodes = newAllNodes.map((node) => {
      // Check if the node type is not "Buttons" or "Carousel"
      if (node.data.type !== "Buttons" && node.data.type !== "Carousel") {
        const isNodeConnected = newAllEdges.some(
          (edge) => edge.source === node.id
        );

        return {
          ...node,
          data: {
            ...node.data,
            end: !isNodeConnected,
          },
        };
      } else {
        // If the node type is "Buttons" or "Carousel", don't make any modifications
        return node;
      }
    });

    setNodes(updatedNodes);

    if (nodeType === "Buttons") {
      // Add another node for "Buttons" type
      const newButtonId = (Date.now() + 1).toString();
      const buttonsNode = {
        id: newButtonId,
        position: { x: newX, y: newY + 150 }, // Adjust the position as needed
        data: {
          label: `Button ${newNode.BtnCounter}`,
          type: "Button",
          id: newButtonId,
          end: true,
        },
        type: "customNode",
        onClose: { handleSliderClose },
      };

      newNode.allButton.push(buttonsNode);

      setNodes((prevNodes) => [...prevNodes, buttonsNode]);

      newNode.chidlrenDataIndex.push(buttonsNode.id);

      const newEdgeForButtons = {
        id: `e${newNode.id}-${buttonsNode.id}`,
        source: newNode.id,
        target: buttonsNode.id,
        animated: true,
      };

      newNode.childrenPathIndex.push(newEdgeForButtons.id);

      setEdges((prevEdges) => [...prevEdges, newEdge, newEdgeForButtons]);
      newNode.BtnCounter = newNode.BtnCounter + 1;
    } else if (nodeType === "Carousel") {
      // Add another node for "Carousel" type
      const newCarouselCardId = (Date.now() + 1).toString();
      newNode.options[0].cardId = newCarouselCardId;
      const carouselCardNode = {
        id: newCarouselCardId,
        position: { x: newX, y: newY + 150 }, // Adjust the position as needed
        data: {
          label: `Card ${newNode.cardCounter}`,
          type: "Card",
          id: newCarouselCardId,
          end: false,
        },
        type: "customNode",
        onClose: { handleSliderClose },
      };

      const newButtonCarouselId = (Date.now() + 2).toString();
      newNode.options[0].buttons[0].id = newButtonCarouselId;
      newNode.options[0].ButtonschidlrenDataIndex.push(newButtonCarouselId);
      const carouselButton = {
        id: newButtonCarouselId,
        position: {
          x: carouselCardNode.position.x,
          y: carouselCardNode.position.y + 150,
        }, // Adjust the position as needed
        data: {
          label: "Button",
          type: "Button",
          id: newButtonCarouselId,
          end: true,
        },
        type: "customNode",
        onClose: { handleSliderClose },
      };

      // newNode.allButton.push(buttonsNode);

      setNodes((prevNodes) => [...prevNodes, carouselCardNode, carouselButton]);

      newNode.chidlrenDataIndex.push(carouselCardNode.id);

      const newEdgeForCarousel = {
        id: `e${newNode.id}-${carouselCardNode.id}`,
        source: newNode.id,
        target: carouselCardNode.id,
        animated: true,
      };

      const newEdgeForCarouselBtn = {
        id: `e${carouselCardNode.id}-${carouselButton.id}`,
        source: carouselCardNode.id,
        target: carouselButton.id,
        animated: true,
      };

      newNode.childrenPathIndex.push(newEdgeForCarousel.id);

      setEdges((prevEdges) => [
        ...prevEdges,
        newEdge,
        newEdgeForCarousel,
        newEdgeForCarouselBtn,
      ]);
    } else {
      // For other node types, just add the edge to the previous logic
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    }

    // Set the button position below the last added node
    setButtonPosition({ x: newX - 40, y: newY + 140 });
  };

  const addChild = (parentIndex) => {
    increaseCount();
    const newButtonId = (Date.now() + 3).toString();
    const buttonsNode = {
      id: newButtonId,
      position: {
        x:
          nodes[parentIndex].position.x +
          nodes[parentIndex].chidlrenDataIndex.length * 110,
        y: nodes[parentIndex].position.y + 150,
      },
      data: {
        label: `Button ${nodes[parentIndex].BtnCounter}`,
        type: "Button",
        id: newButtonId,
        end: true,
      },
      type: "customNode",
      onClose: { handleSliderClose },
    };
    nodes[parentIndex].chidlrenDataIndex.push(buttonsNode.id);
    nodes[parentIndex].allButton.push(buttonsNode);
    setNodes((prevNodes) => [...prevNodes, buttonsNode]);

    const newEdgeForButtons = {
      id: `e${nodes[parentIndex].id}-${buttonsNode.id}`,
      source: nodes[parentIndex].id,
      target: buttonsNode.id,
      animated: true,
    };

    nodes[parentIndex].childrenPathIndex.push(newEdgeForButtons.id);

    setEdges((prevEdges) => [...prevEdges, newEdgeForButtons]);
    nodes[parentIndex].BtnCounter = nodes[parentIndex].BtnCounter + 1;
  };

  const removeButtonFromButtons = (ButtonsIndex, indexBtn, nodeIndex) => {
    increaseCount();
    // Create a copy of the nodes array
    const updatedNodes = [...nodes];

    // Get the specific item at ButtonsIndex
    const nodeAtIndex = updatedNodes[ButtonsIndex];

    // Create a copy of the allButton array inside the node
    const updatedAllButton = [...nodeAtIndex.allButton];

    // Remove the item at indexBtn from the allButton array
    updatedAllButton.splice(indexBtn, 1);

    // Create a copy of the chidlrenDataIndex array inside the node
    const updateChildrenData = [...nodeAtIndex.chidlrenDataIndex];

    // Remove the item at indexBtn from the chidlrenDataIndex array
    updateChildrenData.splice(indexBtn, 1);

    const idOfPathIndex = nodeAtIndex.childrenPathIndex[indexBtn];

    // Create a copy of the chidlrenDataIndex array inside the node
    const updateChildrenPath = [...nodeAtIndex.childrenPathIndex];

    // Remove the item at indexBtn from the chidlrenDataIndex array
    updateChildrenPath.splice(indexBtn, 1);

    // Update the allButton array inside the node
    updatedNodes[ButtonsIndex] = {
      ...nodeAtIndex,
      allButton: updatedAllButton,
      chidlrenDataIndex: updateChildrenData,
      childrenPathIndex: updateChildrenPath,
    };
    updatedNodes.splice(nodeIndex, 1);

    // Create a copy of the edges array
    const updatedEdges = [...edges];

    // Find the index of the edge with matching idOfPathIndex and remove it
    const edgeIndexToRemove = updatedEdges.findIndex(
      (edge) => edge.id === idOfPathIndex
    );

    if (edgeIndexToRemove !== -1) {
      updatedEdges.splice(edgeIndexToRemove, 1);
    }

    // Update the edges array
    setEdges(updatedEdges);

    // Update the nodes array
    setNodes(updatedNodes);
  };

  const addChildCarousel = (parentIndex) => {
    increaseCount();
    const newCarouselCardId = (Date.now() + 3).toString();

    const carouselCardNode = {
      id: newCarouselCardId,
      position: {
        x:
          nodes[parentIndex].position.x +
          nodes[parentIndex].chidlrenDataIndex.length * 150,
        y: nodes[parentIndex].position.y + 150,
      },
      data: {
        label: `Card ${nodes[parentIndex].cardCounter}`,
        type: "Card",
        id: newCarouselCardId,
        end: false,
      },
      type: "customNode",
      onClose: { handleSliderClose },
    };

    const newButtonCarouselId = (Date.now() + 4).toString();

    nodes[parentIndex].options.push({
      cardId: newCarouselCardId,
      img: "",
      text: `Card ${nodes[parentIndex].cardCounter}`,
      description: `<p> Card ${nodes[parentIndex].cardCounter} Description </p>`,
      buttons: [
        { id: newButtonCarouselId, value: "option-1", type: "BranchButton" },
      ],
      ButtonschidlrenDataIndex: [newButtonCarouselId],
      ButtonschildrenPathIndex: [],
    });
    const carouselButton = {
      id: newButtonCarouselId,
      position: {
        x: carouselCardNode.position.x,
        // + nodes[parentIndex].chidlrenDataIndex.length * 110
        y: carouselCardNode.position.y + 150,
      }, // Adjust the position as needed
      data: {
        label: "Button",
        type: "Button",
        id: newButtonCarouselId,
        end: true,
      },
      type: "customNode",
      onClose: { handleSliderClose },
    };
    nodes[parentIndex].chidlrenDataIndex.push(carouselCardNode.id);
    // nodes[parentIndex].allButton.push(carouselCardNode);
    setNodes((prevNodes) => [...prevNodes, carouselCardNode, carouselButton]);

    const newEdgeForButtons = {
      id: `e${nodes[parentIndex].id}-${carouselCardNode.id}`,
      source: nodes[parentIndex].id,
      target: carouselCardNode.id,
      animated: true,
    };

    const newEdgeForCarouselBtn = {
      id: `e${carouselCardNode.id}-${carouselButton.id}`,
      source: carouselCardNode.id,
      target: carouselButton.id,
      animated: true,
    };

    nodes[parentIndex].childrenPathIndex.push(newEdgeForButtons.id);

    setEdges((prevEdges) => [
      ...prevEdges,
      newEdgeForButtons,
      newEdgeForCarouselBtn,
    ]);
  };

  const addButtonsToTheCards = (parentId, carousalIndex, buttonType) => {
    increaseCount();
    const parentIndex = nodes.findIndex((node) => node.id === parentId);
    const optionIndex = nodes[carousalIndex].options.findIndex(
      (option) => option.cardId === parentId
    );
    const newButtonId = (Date.now() + 4).toString();

    let endValue = false;
    if (buttonType === "Branch Button") {
      endValue = true;
    }
    const buttonsNode = {
      id: newButtonId,
      position: {
        x: nodes[parentIndex].position.x,
        y:
          nodes[parentIndex].position.y +
          150 +
          nodes[carousalIndex].options[optionIndex].buttons.length * 90,
      },
      data: {
        label: "Button",
        type: "Button",
        id: newButtonId,
        end: endValue,
      },
      type: "customNode",
      onClose: { handleSliderClose },
    };
    if (buttonType === "Branch Button") {
      nodes[carousalIndex].options[optionIndex].buttons.push({
        id: newButtonId,
        value: "option-1",
        type: "BranchButton",
      });
    } else {
      nodes[carousalIndex].options[optionIndex].buttons.push({
        id: newButtonId,
        value: "option-1",
        URL: "",
        newTab: false,
        type: "URLButton",
      });
    }
    // console.log("nodes[parentIndex]", nodes[parentIndex]);
    nodes[carousalIndex].options[optionIndex].ButtonschidlrenDataIndex.push(
      newButtonId
    );
    setNodes((prevNodes) => [...prevNodes, buttonsNode]);

    const newEdgeForButtons = {
      id: `e${parentId}-${buttonsNode.id}`,
      source: nodes[parentIndex].id,
      target: buttonsNode.id,
      animated: true,
    };

    setEdges((prevEdges) => [...prevEdges, newEdgeForButtons]);
  };

  const removeButtonsCarousel = (buttonsToRemove) => {
    increaseCount();
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.filter((node) => {
        // Check if the node's ID is not in buttonsToRemove
        return !buttonsToRemove.some((button) => button.id === node.id);
      });

      return updatedNodes;
    });
    setEdges((prevEdges) => {
      const updatedEdges = prevEdges.filter((egde) => {
        // Check if the node's ID is not in buttonsToRemove
        return !buttonsToRemove.some((button) => button.id === egde.target);
      });

      return updatedEdges;
    });
  };

  const removeNode = (nodeId) => {
    increaseCount();
    const isButtons = nodes?.find(
      (node) => node.id === nodeId && node.data?.type === "Buttons"
    );

    const isCarousel = nodes?.find(
      (node) => node.id === nodeId && node.data?.type === "Carousel"
    );

    let updatedNodes, updatedEdges;
    if (isButtons) {
      const childrenIds = isButtons.chidlrenDataIndex;
      // console.log("waryny kdh al childrens?", childrenIds);
      updatedNodes = nodes.filter(
        (node) => !childrenIds.includes(node.id) && node.id !== nodeId
      );
      updatedEdges = edges.filter(
        (edge) =>
          !childrenIds.includes(edge.source) &&
          !childrenIds.includes(edge.target) &&
          edge.source !== nodeId &&
          edge.target !== nodeId
      );
    } else if (isCarousel) {
      const childrenIds = isCarousel.chidlrenDataIndex;
      const options = isCarousel.options;
      let buttonsCarousel = [];

      options.forEach((option) => {
        buttonsCarousel = [
          ...buttonsCarousel,
          ...option.ButtonschidlrenDataIndex,
        ];
      });

      updatedNodes = nodes.filter(
        (node) =>
          !(
            childrenIds.includes(node.id) || buttonsCarousel.includes(node.id)
          ) && node.id !== nodeId
      );

      updatedEdges = edges.filter(
        (edge) =>
          !(
            childrenIds.includes(edge.source) ||
            childrenIds.includes(edge.target) ||
            buttonsCarousel.includes(edge.source) ||
            buttonsCarousel.includes(edge.target)
          ) &&
          edge.source !== nodeId &&
          edge.target !== nodeId
      );
    } else {
      updatedNodes = nodes?.filter((node) => node.id !== nodeId);
      // console.log("updatedNodes", updatedNodes);
      updatedEdges = edges?.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
      );
    }

    const updatedNodess = updatedNodes?.map((node) => {
      const isNodeConnected = updatedEdges.some(
        (edge) => edge.source === node.id
      );
      // console.log("is node connected", isNodeConnected);
      return {
        ...node,
        data: {
          ...node.data,
          end: !isNodeConnected,
        },
      };
    });
    setNodes(updatedNodess);
    setEdges(updatedEdges);
  };

  // Function to handle node removal
  const handleRemoveNode = (nodeId) => {
    // You can add any additional logic before removing the node
    removeNode(nodeId);
  };

  const handleNodeDoubleClick = (event, node) => {
    // console.log("handle node double click", node);
    if (
      node.data.type !== "start" ||
      node.data.type !== "Button" ||
      node.data.type !== "Card"
    ) {
      setSelectedNodeType(node.data.type); // Set the selected node type
      setShowSlider(true); // Show the Slider component
      const nodeIndex = nodes.findIndex((n) => n.id === node.id);
      // console.log("nodeIndex", nodeIndex);
      setIndex(nodeIndex);
    }
  };

  const handleSliderClose = () => {
    setShowSlider(false); // Hide the Slider component
  };

  const updateNodeLabel = (newValue) => {
    increaseCount();
    const updatedNodes = [...nodes]; // Create a copy of the nodes array
    updatedNodes[index].data.label = newValue; // Update the label of the selected node
    setNodes(updatedNodes); // Update the state with the modified nodes
  };

  // useEffect(() => {
  //   console.log("al nodes kolha", JSON.stringify(nodes), nodes);
  //   console.log("al pathes kolha", JSON.stringify(edges), edges);
  // }, [nodes, edges]);

  const nodeTypes = useMemo(() => {
    return {
      customNode: (props) => (
        <CustomNode
          {...props}
          toggleMenu={toggleMenu}
          toggleMenuBtn={toggleMenuBtn}
          onSelectedId={setSelectedId}
          removeNode={removeNode}
          handleAddNode={handleAddNode}
          btnPosition={buttonPosition}
          isMenuOpen={isMenuOpen}
          isMenuOpenBtn={isMenuOpenBtn}
          selectedId={selectedId}
        />
      ),
    };
  }, [isMenuOpen, buttonPosition, isMenuOpenBtn, selectedId]);

  // When user reload page
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage =
        "Are you sure you want to leave? Your changes may not be saved.";
      e.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const [showModal, setShowModal] = useState(false);

  // Handle Go back in history
  useEffect(() => {
    if (count > 0) {
      // console.log("Sommmm");
      const handlePopState = () => {
        setShowModal(true);
      };

      window.history.pushState(null, null, window.location.href);
      window.onpopstate = handlePopState;
    }

    return () => {
      window.onpopstate = null;
    };
  }, [count]);

  const handleDiscard = () => {
    setShowModal(false);
    window.history.go(-1); // Leave the page immediately
    // setCount(1);
  };

  const handleSave = () => {
    console.log(chatName);
    // Handle save action, e.g., save data
    setShowModal(false);
    const fetchIdAndMessage = async () => {
      const newData = nodes.map((item) => {
        if (item.data.type === "Send message") {
          const updatedItems = item.items.map((textItem) => {
            if (textItem.type === "text") {
              return {
                ...textItem,
                content: textItem.content.replace(/"/g, "'"),
              };
            }
            return textItem;
          });

          return {
            ...item,
            items: updatedItems,
          };
        }
        return item;
      });
      try {
        const finalObject = {
          Task: newData,
          PathTask: edges,
          nodeCounters: nodeCounters,
        };

        const accNum = localStorage.getItem("AC");

        const dynamicData = {
          ChatName: chatName,
          ChatId: chatId ? chatId : null,
          AccountNumber: `${accNum}`,
          body: finalObject,
        };

        // console.log("dynamicData", dynamicData);

        const testdata = JSON.stringify(dynamicData);

        const response = await axios.get(
          `${baseURL}/chatbot/KBot/AddChatDesignNewVr`,
          {
            params: { iddata: testdata },
          }
        );

        if (response.status === 200) {
          const firstdata = JSON.stringify(response.data[0]?.jsonData);
          const secondata = JSON.parse(JSON.parse(firstdata));
          localStorage.setItem("newChatIdDesign", secondata.ChatId);
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchIdAndMessage();
    window.history.go(-1);
  };

  const closeBackModal = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      {showModal && (
        <BackModal
          close={closeBackModal}
          open={showModal}
          handleDiscard={handleDiscard}
          handleSave={handleSave}
        />
      )}
      {dataisLoading ? (
        // Display the loading screen video that takes the whole page size
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            zIndex: 9999, // Ensure the loading screen is on top of other elements
          }}
        >
          <HashLoader
            color={"#36D7B7"}
            size={"200px"}
            loading={dataisLoading}
          />
        </Box>
      ) : (
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NavBar
            data={nodes}
            dataPath={edges}
            displayErrors={allErrors}
            loading={savingLoading}
            chatName={chatName}
            savetheChatName={savetheChatName}
            nodeCounters={nodeCounters}
          />
          {/* Render selected node type */}
          {showSlider && selectedNodeType && (
            <Slider
              nodeType={selectedNodeType} // Pass the node type as a prop
              onClose={handleSliderClose} // Callback to hide the Slider
              data={nodes}
              index={index}
              isOpen={showSlider}
              pathData={edges}
              reRender={reRender}
              updateNodeLabel={updateNodeLabel} // Pass the callback function
              addChild={addChild}
              removeButtonFromButtons={removeButtonFromButtons}
              addChildCarousel={addChildCarousel}
              addButtonsToTheCards={addButtonsToTheCards}
              remove={handleRemoveNode}
              removeButtonsCarousel={removeButtonsCarousel}
              settingErrors={setAllErrors}
            />
          )}

          {/* the react flow  */}
          <Box
            style={{
              position: "relative",
              width: "100vw", // Set a width large enough to allow horizontal scrolling
              height: "100vh", // Set a height large enough to allow vertical scrolling
            }}
          >
            <ReactFlow
              zoomOnScroll={false}
              zoomOnDoubleClick={false}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              // onConnect={onConnect}
              onConnect={(params) => {
                onConnect(params);
              }}
              onNodeDoubleClick={handleNodeDoubleClick} // Add this line
              preventScrolling={false}
              nodeTypes={nodeTypes}
              deleteKeyCode={null}
            >
              <Controls />
              <MiniMap />
              <Background
                variant="dots"
                color="#d3cfd2"
                Background-color="black"
                gap={12}
                size={2}
              />
            </ReactFlow>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
