import { Routes, Route } from "react-router-dom";
import routes from "./routes";

import ViewChatBot from "../features/viewer/pages/InterviewChatbot";
import Design from "../features/design/pages/Design";
import Login from "../features/Auth/page/Login";
import CheckEmail from "../features/Auth/page/forrgetPassword/components/CheckEmail";
import OtpCheckEmail from "../features/Auth/page/forrgetPassword/components/OtpCheckEmail";
import NewPassword from "../features/Auth/page/forrgetPassword/components/NewPassword";
import Register from "../features/Auth/page/Register";
import ChatsPage from "../features/charts/Page/ChatsPage";
import AllChats from "../features/allChats/page/AllChats";
import EmbededChat from "../features/viewer/components/EmbededChat";

import SendMsgUse from "../features/howToUse/SendMsgUse";
import CollectInputUse from "../features/howToUse/CollectInputUse";
import ButtonsUse from "../features/howToUse/ButtonsUse";
import Calendar from "../features/howToUse/Calendar";
import SendEmailUse from "../features/howToUse/SendEmailUse";
import ImgCarouselUse from "../features/howToUse/ImgCarouselUse";
import SliderUse from "../features/howToUse/SliderUse";
import FileUploadUse from "../features/howToUse/FileUploadUse";
import DelayUse from "../features/howToUse/DelayUse";
import FormUse from "../features/howToUse/FormUse";
import ProtectedRoutes from "./ProtectedRoutes";
import DocLayOut from "./DocLayOut";
import ProtectedDesign from "./designProtected/ProtectedDesign";
import ChatTemplates from "../features/chatTemplates/page/ChatTemplates";
import CarousalUse from "../features/howToUse/CarousalUse";
import FlowUse from "../features/howToUse/FlowUse";
import UnderConstraction from "../components/ui/underConstruction/UnderConstraction";

const Navigations = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.register} element={<Register />} />
      <Route path={routes.checkEmail} element={<CheckEmail />} />
      <Route path={routes.otpCheckEmail} element={<OtpCheckEmail />} />
      <Route path={routes.newPassword} element={<NewPassword />} />
      <Route path={routes.embeded} element={<EmbededChat />} />

      <Route element={<ProtectedRoutes />}>
        <Route path={routes.charts} element={<ChatsPage />} />
        <Route path={routes.allChats} element={<AllChats />} />
        <Route path={routes.templates} element={<ChatTemplates />} />
        <Route
          path={routes.underConstraction}
          element={<UnderConstraction />}
        />
      </Route>

      <Route element={<ProtectedDesign />}>
        <Route path={routes.dashboard} element={<Design />} />
        <Route path={routes.newDashboard} element={<Design />} />
        <Route path={routes.viewer} element={<ViewChatBot />} />
      </Route>

      <Route element={<DocLayOut />}>
        <Route path={routes.sendMsgUse} element={<SendMsgUse />} />
        <Route path={routes.collectInpUse} element={<CollectInputUse />} />
        <Route path={routes.buttonsUse} element={<ButtonsUse />} />
        <Route path={routes.calendarUse} element={<Calendar />} />
        <Route path={routes.sendEmailUSe} element={<SendEmailUse />} />
        <Route path={routes.ImgCarouselUse} element={<ImgCarouselUse />} />
        <Route path={routes.SliderUse} element={<SliderUse />} />
        <Route path={routes.FileUploadUse} element={<FileUploadUse />} />
        <Route path={routes.DelayUse} element={<DelayUse />} />
        <Route path={routes.FormUse} element={<FormUse />} />
        <Route path={routes.CarouselUse} element={<CarousalUse />} />
        <Route path={routes.FlowUse} element={<FlowUse />} />
      </Route>
    </Routes>
  );
};

export default Navigations;
