import { Box, Typography } from "@mui/material";

import Styles from "./styles/instructions.module.css";

const FlowUse = () => {
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Flow</h1>
          <Typography className={Styles.mb20}>
            This action block allows you to Connect two deleted components.
          </Typography>

          <Box className={Styles.importantText}>
            You Can connect two components only with the flow (flow component
            doesn't count as a component that can be connected here).
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlowUse;
