import React, { useState, useEffect } from "react";
import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import Reactquill from "../../../components/ui/reactquill/Reactquill";
import DesignButton from "../../../components/ui/button/DesignButton";

import { Box, TextField, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import "./styles/slider.css";

const ButtonsOptions = ({ allData }) => {
  // chidlrenDataIndex mkano fy al nodes
  // childrenPathIndex mkano fy al path

  const [editorContent, setEditorContent] = useState([""]);

  useEffect(() => {
    const updatedEditorContent = [...editorContent];
    updatedEditorContent[0] = allData.data[allData.index].data.description;
    setEditorContent(updatedEditorContent);
  }, [allData.index, allData.data]);

  useEffect(() => {
    const emptyTextIndicesUpdated = allData?.data[allData.index].allButton
      .map((item, idx) => (item.data.label.trim() === "" ? idx : null))
      .filter((idx) => idx !== null);

    // Log the label for each item in allButton
    allData?.data[allData.index].allButton.forEach((item) => {
      // console.log("Button Label:", item.data.label.trim());
    });

    const newEmptyTextErrors = emptyTextIndicesUpdated.map((index) => ({
      id: allData.data[allData.index].data.id,
      title:
        allData.data[allData.index].data.label === ""
          ? "Untitled"
          : `${allData.data[allData.index].data.label}`,
      subject: `The button name cannot be empty`,
    }));

    allData.settingErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter(
        (error) =>
          !(
            error.id === allData.data[allData.index].data.id &&
            error.subject === `The button name cannot be empty`
          )
      );

      return [...updatedErrors, ...newEmptyTextErrors];
    });

    const allButtons = allData.data[allData.index].allButton;
    const duplicateButtonNames = new Set();
    const duplicateButtonIndices = [];

    // Check for identical button labels
    allButtons.forEach((button, currentIndex) => {
      // Skip buttons with empty labels
      if (button.data.label.trim() === "") {
        return;
      }

      if (duplicateButtonNames.has(button.data.label.trim().toLowerCase())) {
        duplicateButtonIndices.push(currentIndex);
      } else {
        duplicateButtonNames.add(button.data.label.trim().toLowerCase());
      }
    });

    const newDuplicateButtonErrors = duplicateButtonIndices.map((index) => ({
      id: allData.data[allData.index].data.id,
      title:
        allData.data[allData.index].data.label === ""
          ? "Untitled"
          : `${allData.data[allData.index].data.label}`,
      subject: `This Field Name Is Already In Use`,
    }));

    allData.settingErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter(
        (error) =>
          !(
            error.id === allData.data[allData.index].data.id &&
            error.subject === `This Field Name Is Already In Use`
          )
      );

      return [...updatedErrors, ...newDuplicateButtonErrors];
    });
  }, [
    allData.data[allData.index].data.label,
    allData.data[allData.index].allButton,
  ]);

  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Send message will only send messages to the user and not require a response back"
        }
        toastHeader="Buttons"
        nodeType={allData.nodeType}
      ></SliderHeader>

      {editorContent.map((content, index2) => (
        <Box className="slider-comp">
          <Box className="disc">
            <p className="message-description">Send this message</p>
          </Box>
          <Reactquill
            ediotrs={editorContent}
            cont={content}
            index={index2}
            Data={allData}
            placehold="What would you like to choose?"
          ></Reactquill>
        </Box>
      ))}

      <Box
        className="add-btn-list"
        style={{
          marginTop: "30px",
          marginBottom: "120px",
        }}
      >
        <Box
          style={{
            borderBottom: "3px solid #7d7d7d",
          }}
        >
          {allData?.data[allData.index]?.chidlrenDataIndex?.map(
            (item, index) => {
              const nodeIndex = allData.data.findIndex(
                (node) => node.id === item
              );
              return (
                <Box className="buttons-info">
                  <Box className="all-button-data">
                    <Box className="name-area">
                      <TextField
                        className="button-name"
                        autoFocus
                        multiline
                        inputProps={{ maxLength: 75 }}
                        value={
                          allData &&
                          allData.data[nodeIndex] &&
                          allData.data[nodeIndex].data &&
                          allData.data[nodeIndex].data.label
                        }
                        onChange={(e) => {
                          allData.data[nodeIndex].data.label = e.target.value;
                          allData.reRender();

                          const updatedButtons = allData.data[
                            allData.index
                          ].allButton.map((button) =>
                            item === button.id
                              ? {
                                  ...button,
                                  data: {
                                    ...button.data,
                                    label: e.target.value,
                                  },
                                }
                              : button
                          );
                          allData.data[
                            allData.index
                          ].allButton = updatedButtons;
                        }}
                      ></TextField>
                    </Box>

                    {allData?.data[allData.index]?.chidlrenDataIndex.length >
                    1 ? (
                      <Box className="delet-btn">
                        <DeleteOutlinedIcon
                          className="delete-outlined-icon"
                          onClick={() => {
                            allData.removeButtonFromButtons(
                              allData.index,
                              index,
                              nodeIndex
                            );
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Typography
                    style={{
                      fontSize: "11px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "#7d7d7d",
                    }}
                  >
                    Add new blocks from the part to configure it.
                  </Typography>
                </Box>
              );
            }
          )}
        </Box>
        {allData?.data[allData.index]?.chidlrenDataIndex.length < 10 && (
          <DesignButton
            func={() => allData.addChild(allData.index)}
            text="Add button"
          ></DesignButton>
        )}
      </Box>
    </Box>
  );
};

export default ButtonsOptions;
