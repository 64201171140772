import React, { useEffect, useState } from "react";
import ChatBot from "react-simple-chatbot";
import DateInput from "../components/calender/DateInput";
import TimeInput from "../components/calender/TimeInput";
import SliderCards from "../components/imageSlider/Slider";
import SliderOption from "../components/slider/SliderOption";
import ChatForm from "../components/forms/ChatForm";
import FileUploadChat from "../components/uploadFiles/FileUploadChat";
import SendFileMessage from "../components/sendMessage/SendFileMessage";
import SendQuillMessage from "../components/sendMessage/SendQuillMessage";
import ReactHtmlParser from "react-html-parser";

import logo from "../../../assets/images/logo.jpg";

import { Box, Typography } from "@mui/material";
import CollectInput from "./inputCollect/CollectInput";
import { useParams } from "react-router-dom";
import CarouselSlider from "./carousel/CarouselSlider";
import SendAnEmail from "./sendAnEmail/SendAnEmail";
import { baseURL } from "../../../axiosApi/axiosApi";
import Buttons from "./buttons/Buttons";
const CustomHeader = () => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: " #a7abaa",
        padding: "10px 0px 7px 10px",
      }}
    >
      <Box style={{ marginRight: "15px" }}>
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "50px",
            height: "50px",
            marginRight: "10px",
            borderRadius: "50%",
          }}
        />
      </Box>
      <Box>
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "400",
            color: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Niyat - نياط
        </Typography>
      </Box>
    </Box>
  );
};

const InterviewChatbot = () => {
  const pars = useParams();

  const chatId = pars.chatId;

  const accountNumber = pars.accNum;

  const [Finaldata, setFinalData] = useState([]);

  // to store the user inputs
  const [userResponses, setUserResponses] = useState([]);
  const handleUserInput = (response) => {
    // Store the user's response in the state
    setUserResponses((prevResponses) => [...prevResponses, response]);
  };

  const [selectedDate, setSelectedDate] = useState();

  const UserDate = (response) => {
    setSelectedDate(response);
    // console.log("Selected Date in UserDate function:", response);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseURL}/chatbot/KBot/GetChatViewNewVr?iddata={"AccountNumber":"${accountNumber}","ChatId":"${chatId}"}`
        );
        // console.log(response);
        if (response.status === 200) {
          const dataa = await response.json();

          // console.log("totos1", dataa[0]?.jsonData);

          const secondData = JSON.parse(dataa[0]?.jsonData);

          console.log("totos2", secondData);
          setFinalData(secondData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleChatEnd = async () => {
    const requestData = {
      AccountNumber: `${accountNumber}`,
      ChatId: chatId,
      Data: userResponses,
    };

    // console.log("Request data", requestData);
    try {
      await fetch(
        `${baseURL}/chatbot/KBot/FN2?iddata=${JSON.stringify(requestData)}`
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUserInputt = (value, reqName) => {
    const withoutQuotes = reqName?.replace(/'/g, "");
    const transform = (node, index) => {
      if (
        node.type === "tag" &&
        node.name === "a" &&
        node.attribs &&
        node.attribs.href
      ) {
        if (node.attribs.href.startsWith("www.")) {
          node.attribs.href = `http://${node.attribs.href}`;
        }
      }
      return undefined;
    };
    const parsedData = ReactHtmlParser(withoutQuotes, { transform });
    setUserResponses((prevResponses) => [
      ...prevResponses,
      { request: parsedData[0]?.props?.children[0], response: value.value },
    ]);
  };

  let nextComponentDelay = 1000;
  const updatedSteps = Finaldata?.map((step, index) => {
    const updatedStep = { ...step };
    if (updatedStep.component === "DateInput") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component,
        waitAction: true,
        delay: nextComponentDelay,
      };

      modifiedStep.component = (
        <DateInput
          calenderHeader={updatedStep.text}
          disabledDates={updatedStep.disabledDates}
          disabledDays={updatedStep.disabledDays}
          onInputSubmit={handleUserInput}
          userSelectedDate={UserDate}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "TimeInput") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component,
        waitAction: true,
        delay: nextComponentDelay,
      };

      modifiedStep.component = (
        <TimeInput
          timeHeader={updatedStep.text}
          allTimes={updatedStep.times}
          onInputSubmit={handleUserInput}
          dividedTimes={updatedStep.dividedTimes}
          SelectionDate={selectedDate}
          key={selectedDate}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "SliderCards") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component,
        delay: nextComponentDelay,
      };

      modifiedStep.component = <SliderCards theURL={updatedStep.SliderCards} />;
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "ChatForm") {
      // Set the component property for image steps
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        waitAction: true,
        component: updatedStep.component,
        delay: nextComponentDelay,
      };

      modifiedStep.component = (
        <ChatForm
          allForms={updatedStep.ChatForm}
          formHeader={updatedStep.text}
          onInputSubmit={handleUserInput}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "SliderOption") {
      const modifiedStep = {
        id: updatedStep.id,
        trigger: updatedStep.trigger,
        component: updatedStep.component,
        waitAction: true,
        delay: nextComponentDelay,
      };

      modifiedStep.component = (
        <SliderOption
          from={updatedStep.from}
          to={updatedStep.to}
          countStep={updatedStep.countStep}
          enableCount={updatedStep.enableCount}
          unitSlider={updatedStep.unitSlider}
          onInputSubmit={handleUserInput}
          optionHeader={updatedStep.text}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "FileUpload") {
      const modifiedStep = {
        id: updatedStep.id,
        component: updatedStep.component,
        waitAction: true,
        trigger: updatedStep.trigger,
        delay: nextComponentDelay,
      };
      modifiedStep.component = (
        <FileUploadChat
          maxFileSize={updatedStep.maxFileSize}
          maxAllowedFiles={updatedStep.maxAllowedFiles}
          allowedExtenstions={updatedStep.extensions}
          fileUploadHeader={updatedStep.text}
          onInputSubmit={handleUserInput}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "SendFileMessage") {
      const modifiedStep = {
        id: updatedStep.id,
        component: updatedStep.component,
        trigger: updatedStep.trigger,
        asMessage: true,
        delay: nextComponentDelay,
      };
      modifiedStep.component = (
        <SendFileMessage
          path={updatedStep.path}
          text={updatedStep.text}
          fileType={updatedStep.fileType}
        />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "SendQuillMessage") {
      const modifiedStep = {
        id: updatedStep.id,
        component: updatedStep.component,
        trigger: updatedStep.trigger,
        asMessage: true,
        delay: nextComponentDelay,
      };
      modifiedStep.component = <SendQuillMessage cont={updatedStep.content} />;
      let delay = 1000;

      const contentLength = updatedStep.content.length;

      if (contentLength < 20) {
        delay = 1000;
      } else if (contentLength >= 20 && contentLength < 30) {
        delay = 2000;
      } else if (contentLength >= 30 && contentLength < 40) {
        delay = 3000;
      } else {
        delay = 5000;
      }

      nextComponentDelay = delay;
      return modifiedStep;
    } else if (updatedStep.component === "CollectInput") {
      const modifiedStep = {
        id: updatedStep.id,
        component: updatedStep.component,
        trigger: updatedStep.trigger,
        asMessage: updatedStep.asMessage,
        delay: nextComponentDelay,
      };
      modifiedStep.component = <CollectInput cont={updatedStep.content} />;
      nextComponentDelay = 1000;
      return modifiedStep;
    } else if (updatedStep.component === "Buttons") {
      const modifiedStep = {
        id: updatedStep.id,
        component: updatedStep.component,
        delay: nextComponentDelay,
      };
      modifiedStep.component = (
        <Buttons btns={updatedStep.allButtons} desc={updatedStep.text} />
      );
      nextComponentDelay = 1000;
      return modifiedStep;
    }
    // console.log("b3d al step", updatedStep);
    return updatedStep;
  });

  // console.log("updatedSteps", updatedSteps);

  // Function to modify the array of objects
  const modifyObjects = (arr) => {
    return arr.map((obj) => {
      if (obj.message === "previousValue") {
        obj.message = ({ previousValue }) => `${previousValue}`;
      }
      return obj;
    });
  };

  // Call the function to modify the array
  const modifiedArray = modifyObjects(updatedSteps);

  // console.log("modified array", modifiedArray);

  // Identify the ID for the final step
  const finalStepId = "finalStep";

  const updateTriggerForUser = (originalArray) => {
    const newArray = [...originalArray];

    for (let i = 0; i < newArray.length; i++) {
      const currentObject = newArray[i];

      if (currentObject.user === true) {
        const nextTriggerValue = currentObject.trigger;

        currentObject.validator = (value) => {
          if (value.trim() === "") {
            return "value shouldn't be empty";
          }
          return true;
        };
        currentObject.trigger = (value) => {
          handleUserInputt(value, newArray[i - 1].component.props.cont);
          if (typeof nextTriggerValue === "undefined") {
            return finalStepId;
          } else {
            return nextTriggerValue;
          }
        };
      }
    }
    return newArray;
  };

  const collcetTrigger = updateTriggerForUser(modifiedArray);

  // Find all IDs associated with steps having the "Flow" component
  const flowStepIds = collcetTrigger
    .filter((step) => step.component === "Flow")
    .map((flowStep) => flowStep.id);

  // Update triggers in the remaining steps
  const updatedStepsWithoutFlow = collcetTrigger.map((step) => {
    const updatedStep = { ...step };

    // If the step's trigger matches any of the IDs of the "Flow" steps, update it
    if (flowStepIds.includes(updatedStep.trigger)) {
      const matchingFlowStep = updatedSteps.find(
        (flowStep) => flowStep.id === updatedStep.trigger
      );
      if (matchingFlowStep) {
        updatedStep.trigger = matchingFlowStep.trigger;
      }
    }

    return updatedStep;
  });

  // Now, you can create the stepsWithoutFlow array by excluding the steps with the "Flow" component
  const stepsWithoutFlow = updatedStepsWithoutFlow.filter(
    (step) => step.component !== "Flow"
  );

  for (let i = 0; i < stepsWithoutFlow.length; i++) {
    const currentStep = stepsWithoutFlow[i];
    console.log(currentStep?.component?.props?.btns);
    console.log(currentStep);
    if (
      !currentStep.trigger &&
      !currentStep.user &&
      !currentStep?.component?.props?.btns
    ) {
      currentStep.trigger = finalStepId;
    }
  }

  // Add the final step to the array
  const finalStep = {
    id: finalStepId,
    message: "This is the end of the chat.",
    delay: nextComponentDelay,
    end: true,
  };

  stepsWithoutFlow.push(finalStep);

  const stepsWithDelay = [];
  let delay = 0;
  let id = 0;

  stepsWithoutFlow.forEach((step, index) => {
    if (step.component === "Delay") {
      delay = parseInt(step.delay) * 1000;
      id = step.id;
    } else {
      const stepWithDelay = {
        ...step,
        ...(delay && { delay }), // Add delay only if it's not 0
        ...(id && { id }), // Add id only if it's not 0
      };
      stepsWithDelay.push(stepWithDelay);

      // Reset delay and id for the next iteration
      delay = 0;
      id = 0;
    }
  });

  // Modify the step that should trigger the final step
  // In this example, I'll use the last step in the existing array
  // const lastStepIndex = stepsWithDelay.length - 1;
  // if (lastStepIndex >= 0) {
  //   const lastStep = stepsWithDelay[lastStepIndex];
  //   if (!lastStep.trigger) {
  //     lastStep.trigger = finalStepId;
  //   } else {
  //     lastStep.trigger = (value) => {
  //       handleUserInputt(value);
  //       return finalStepId;
  //     };
  //   }
  // }

  // console.log("final steps", stepsWithDelay);

  // const CustomOptionsNode = ({ options, triggerNextStep }) => {
  //   const [selectedOption, setSelectedOption] = useState("");

  //   const handleOptionSelect = (option) => {
  //     setSelectedOption(option.value);
  //     triggerNextStep({ trigger: option.trigger });
  //   };

  //   return (
  //     <div>
  //       <p>Choose an option:</p>
  //       {options.map((option) => (
  //         <button key={option.value} onClick={() => handleOptionSelect(option)}>
  //           {option.label}
  //         </button>
  //       ))}
  //     </div>
  //   );
  // };

  // const emailStepsss = [
  //   {
  //     id: "1",
  //     message: "Welcome to the chatbot!",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     message: "This step triggers the API call",
  //     trigger: "apiAction",
  //   },
  //   {
  //     id: "apiAction",
  //     component: <SendAnEmail />,
  //     waitAction: true, // Wait for the API call to complete before moving to the next step
  //     trigger: "3",
  //     hideInput: true, // Hide this step in the chat
  //   },
  //   {
  //     id: "3",
  //     message: "This is the next step after the API call",
  //     end: true,
  //   },
  // ];

  console.log(stepsWithDelay);

  const stepsssss = [
    {
      id: "1",
      message: "Welcome to the chat bot!",
      trigger: "2",
    },
    {
      id: "2",
      component: (
        <Buttons
          btns={[
            {
              value: "Option 1",
              trigger: "3",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
            {
              value: "Option 2",
              trigger: "4",
            },
          ]}
          desc="<p>What?</p>"
        />
      ),
      waitAction: true,
    },
    {
      id: "3",
      message: "You chose Option 1",
      end: true,
    },
    {
      id: "4",
      message: "You chose Option 2",
      end: true,
    },
    {
      id: "5",
      message: "You chose Option 3",
      end: true,
    },
  ];

  return Finaldata.length > 0 ? (
    <ChatBot
      steps={stepsWithDelay}
      botAvatar={logo}
      headerComponent={<CustomHeader />}
      botDelay={500}
      handleEnd={handleChatEnd}
      // customDelay={500}
      style={{ width: "400px", height: "65vh" }}
    />
  ) : (
    <div style={{ color: "white" }}>Loading... </div>
  );
};

export default InterviewChatbot;
