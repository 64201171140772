import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import CandlestickChartTwoToneIcon from "@mui/icons-material/CandlestickChartTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import UpdateIcon from "@mui/icons-material/Update";
import ViewDayTwoToneIcon from "@mui/icons-material/ViewDayTwoTone";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ open }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Adjust the open state based on screen size
  const openState = isSmallScreen ? false : open;
  const handleNavigate = (type) => {
    if (type === "Send Message") {
      navigate("/sendMessage");
    } else if (type === "Collect Input") {
      navigate("/collectInput");
    } else if (type === "Buttons") {
      navigate("/buttons");
    } else if (type === "Calendar") {
      navigate("/calendar");
    } else if (type === "Send an Email") {
      navigate("/sendEmail");
    } else if (type === "Image Carousel") {
      navigate("/ImgCarousel");
    } else if (type === "Slider") {
      navigate("/Slider");
    } else if (type === "File Upload") {
      navigate("/FileUpload");
    } else if (type === "Delay") {
      navigate("/Delay");
    } else if (type === "Form") {
      navigate("/Form");
    } else if (type === "Carousal") {
      navigate("/Carousel");
    } else if (type === "Flow") {
      navigate("/Flow");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={openState}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            "Send Message",
            "Collect Input",
            "Buttons",
            "Calendar",
            "Send an Email",
            "Image Carousel",
            "Slider",
            "File Upload",
            "Delay",
            "Carousal",
            "Form",
            "Flow",
          ].map((text, index) => (
            <Tooltip title={text} placement="right">
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleNavigate(text)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text === "Send Message" && <ChatTwoToneIcon />}
                    {text === "Collect Input" && (
                      <CandlestickChartTwoToneIcon />
                    )}
                    {text === "Buttons" && <RadioButtonCheckedTwoToneIcon />}
                    {text === "Calendar" && <CalendarMonthTwoToneIcon />}
                    {text === "Send an Email" && <EmailTwoToneIcon />}
                    {text === "Image Carousel" && <ViewCarouselTwoToneIcon />}
                    {text === "Slider" && <TuneTwoToneIcon />}
                    {text === "File Upload" && <InsertDriveFileTwoToneIcon />}
                    {text === "Delay" && <UpdateIcon />}
                    {text === "Form" && <FormatListBulletedTwoToneIcon />}
                    {text === "Carousal" && <ViewDayTwoToneIcon />}
                    {text === "Flow" && <AccountTreeIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
