import { Box, Typography } from "@mui/material";
import Styles from "./constraction.module.css";
import constactionImg from "../../../assets/images/under_construction.png";

const UnderConstraction = () => {
  return (
    <Box className={Styles.constractionCont}>
      <img src={constactionImg} alt="" />
      <Box className={Styles.textCont}>
        <h2> Site Under Construction</h2>
        <Typography>
          We're working hard to bring you a better experience. Please check back
          later!
        </Typography>
      </Box>
    </Box>
  );
};

export default UnderConstraction;
