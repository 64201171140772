import React from "react";
import { Box, Button } from "@mui/material";
import { getPageNumbers } from "../../utils/helpers";
import Styles from "./Chats.module.css";

const Pagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  handlePageClick,
}) => {
  return (
    totalPages > 1 && (
      <Box className={Styles.pagination}>
        <Button
          className={Styles.paginationBtn}
          disabled={currentPage === 1}
          onClick={handlePrevPage}
          variant="contained"
        >
          Previous
        </Button>

        {/* Render page numbers */}
        <Box className={Styles.pageNumbers}>
          {/* Render previous ellipsis if needed */}
          {currentPage > 2 && (
            <Button
              className={Styles.paginationBtn}
              variant="contained"
              disabled
            >
              ...
            </Button>
          )}

          {getPageNumbers(currentPage, totalPages)?.map((pageNumber) => (
            <Button
              className={Styles.paginationBtn}
              key={pageNumber}
              onClick={() => handlePageClick(pageNumber)}
              variant="contained"
              disabled={currentPage === pageNumber}
            >
              {pageNumber}
            </Button>
          ))}

          {/* Render next ellipsis if needed */}
          {currentPage < totalPages - 1 && (
            <Button
              className={Styles.paginationBtn}
              variant="contained"
              disabled
            >
              ...
            </Button>
          )}
        </Box>

        <Button
          className={Styles.paginationBtn}
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    )
  );
};

export default Pagination;
