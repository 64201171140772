import { createRef, useRef, useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import ReactHtmlParser from "react-html-parser";
import { useForm, Controller } from "react-hook-form";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import "../../Styles/chatbot.css";

function ChatForm({ allForms, onInputSubmit, formHeader, triggerNextStep }) {
  const withoutQuotes = formHeader.replace(/'/g, "");
  const { control, handleSubmit, setValue, formState, trigger } = useForm();

  const transform = (node, index) => {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs &&
      node.attribs.href
    ) {
      if (node.attribs.href.startsWith("www.")) {
        node.attribs.href = `http://${node.attribs.href}`;
      }
    }
    return undefined;
  };
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Add a state variable to store user inputs
  const initialValues = allForms.map((el) => ({ text: el.value, value: "" }));
  const [userInputs, setUserInputs] = useState(initialValues);

  const handleInputChange = async (index, val, elValue) => {
    // Check if the userInputs array already contains the specified value
    const existingInputIndex = userInputs.findIndex(
      (input) => input.value === elValue
    );

    // If the value is found, update its text property
    if (existingInputIndex !== -1) {
      const updatedUserInputs = [...userInputs];
      updatedUserInputs[existingInputIndex].text = elValue;
      setUserInputs(updatedUserInputs);
    } else {
      // If the value is not found, create a new object and add it to the array
      const updatedUserInput = { text: elValue, value: val };
      const updatedUserInputs = [...userInputs];
      updatedUserInputs[index] = updatedUserInput;
      setUserInputs(updatedUserInputs);
    }

    setValue(elValue, val);
    await trigger(elValue);
  };

  // Event handler for form submission
  const submit = () => {
    const parsedData = ReactHtmlParser(withoutQuotes, { transform });

    // Pass the user inputs back to the parent component
    onInputSubmit({
      formName: parsedData[0].props.children[0],
      formData: userInputs,
    });
    setFormSubmitted(true);
    triggerNextStep();
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };
  // const [startDate, setStartDate] = useState(null);
  const datePickerRefs = useRef(
    allForms.filter((el) => el.type === "date").map(() => createRef())
  );

  const openDatePicker = (index) => {
    if (datePickerRefs) {
      datePickerRefs?.current[index]?.current.setOpen(true);
    }
  };
  return (
    <Box className="forms chatbot-container">
      <Box className="formPicker chatbot-shadow">
        <LogoContainer
          textHeader={ReactHtmlParser(withoutQuotes, { transform })}
        ></LogoContainer>
        <form onSubmit={handleSubmit(submit)} className="main-form">
          {allForms?.map((el, index) => {
            return (
              <Box key={index} className="divchatbot">
                {el.type === "date" ? (
                  <>
                    <Controller
                      name={el.value}
                      control={control}
                      rules={{
                        required:
                          el.optional === true
                            ? "This field is required"
                            : false,
                      }}
                      render={({ field }) => (
                        <Box
                          className={`dateTypeContainer ${
                            formSubmitted ? "disabledDatePicker" : ""
                          }`}
                          onClick={() => openDatePicker(index)}
                        >
                          <DatePicker
                            ref={datePickerRefs?.current[index]}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              handleInputChange(index, date, el.value);
                            }}
                            placeholderText={
                              el.value.charAt(0).toUpperCase() +
                              el.value.slice(1) +
                              (el.optional ? " *" : "")
                            }
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            dayClassName={(date) => "custom-day"}
                            disabled={formSubmitted}
                          />
                          <CalendarMonthIcon
                            style={{ cursor: "pointer" }}
                            className="my-calender"
                            onClick={() => openDatePicker(index)}
                          />
                        </Box>
                      )}
                    />

                    {formState.errors[el.value] && (
                      <Typography className="error-message">
                        {formState.errors[el.value]?.message}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Controller
                      name={el.value}
                      control={control}
                      rules={{
                        required:
                          el.optional === true
                            ? "This field is required"
                            : false,
                        ...(el.type === "text" &&
                          el.optional === true && {
                            minLength: {
                              value: 2,
                              message: "Minimum length is 2 characters",
                            },
                            maxLength: {
                              value: 25,
                              message: "Maximum length is 25 characters",
                            },
                            pattern: {
                              value: /^[\p{L}\s]+$/u,
                              message: "Invalid name format",
                            },
                            validate: {
                              noOnlySpaces: (value) =>
                                !isOnlySpaces(value) || "Cannot be only spaces",
                            },
                          }),
                        ...(el.type === "number" &&
                          el.optional === true && {
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return !isNaN(intValue) &&
                                intValue >= 0 &&
                                Number.isInteger(intValue)
                                ? true
                                : "Must be a non-negative integer without any characters";
                            },
                          }),
                        ...(el.type === "email" &&
                          el.optional === true && {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email",
                            },
                            validate: {
                              noOnlySpaces: (value) =>
                                !isOnlySpaces(value) || "Cannot be only spaces",
                            },
                          }),
                        ...(el.type === "tel" &&
                          el.optional === true && {
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return !isNaN(intValue) &&
                                intValue >= 0 &&
                                Number.isInteger(intValue)
                                ? true
                                : "Must be a non-negative integer without any characters";
                            },
                          }),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputchatbot"
                          type={el.type}
                          placeholder={
                            el.value.charAt(0).toUpperCase() +
                            el.value.slice(1) +
                            (el.optional ? " *" : "")
                          }
                          onChange={(e) =>
                            handleInputChange(index, e.target.value, el.value)
                          }
                          disabled={formSubmitted}
                        />
                      )}
                    />
                    {formState.errors[el.value] && (
                      <Typography className="error-message">
                        {formState.errors[el.value]?.message}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            );
          })}
          <Box className="divchatbotsubmit">
            <Button
              type="submit"
              className="submitchatbot"
              disabled={formSubmitted}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ChatForm;
