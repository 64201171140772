import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { htmlToText } from "html-to-text";
import { Box } from "@mui/material";

const Reactquill = ({ ediotrs, cont, index, Data, placehold }) => {
  const [emptyEditor, setEmptyEditor] = useState(false);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };

  const converContToText = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });

    return text;
  };

  const handleEditorChange = (html) => {
    if (Data.data[Data.index].data.description.length > 400) {
      return;
    }

    if (converContToText?.(html)?.trim() === "") {
      setEmptyEditor(true);
    } else {
      setEmptyEditor(false);
    }

    ediotrs[index] = html;

    Data.data[Data.index].data.description = html.replace(/"/g, "'");

    Data.reRender();
  };

  useEffect(() => {
    if (
      converContToText(Data.data[Data.index].data.description).trim() === ""
    ) {
      const newEmptyTextErrors = {
        id: Data.data[Data.index].data.id,
        title:
          Data.data[Data.index].data.label === ""
            ? "Untitled"
            : `${Data.data[Data.index].data.label}`,
        subject: "There is no text added in this action block",
      };

      Data.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === Data.data[Data.index].data.id &&
              error.subject === "There is no text added in this action block"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptyEditor is false
      Data.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === Data.data[Data.index].data.id &&
              error.subject === "There is no text added in this action block"
            )
        )
      );
    }
  }, [
    Data.data[Data.index].data.description,
    Data.data[Data.index].data.label,
  ]);

  const formats = ["bold", "italic", "underline", "list", "link"];
  return (
    <Box className="quill">
      <ReactQuill
        key={index}
        placeholder={placehold}
        theme="snow"
        value={cont}
        onChange={(content) => {
          handleEditorChange(content);
        }}
        modules={modules}
        formats={formats}
      />
    </Box>
  );
};

export default Reactquill;
