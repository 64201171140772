import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Styles from "./styles/instructions.module.css";

const FormUse = () => {
  const [activeLink, setActiveLink] = useState(null);
  const navigate = useNavigate();

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Form</h1>
          <Typography className={Styles.mb20}>
            Action block provides the easiest way of collecting multiple inputs
            from your visitors
          </Typography>

          <Typography className={Styles.mb10}>
            In scenarios, where you are looking to collect multiple inputs like
            name, email or phone to confirm the appointment, rather than making
            the conversation lengthy by using multiple collect input blocks to
            collect each value, you can make use of form that collects
            everything at once.
          </Typography>
          <h3 id="1">When should I use it?</h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>You are collecting multiple inputs</li>
            <li className={Styles.mb20}>
              Want to keep the chat short and quick
            </li>
            <li className={Styles.mb20}>Reduce the number of to and fros</li>
          </ul>

          <h3 id="2">How do I set it up? </h3>
          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up 'Form' action block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block on canvas by clicking on '+'{" "}
            </li>
            <li className={Styles.mb20}>Choose 'Form'</li>
            <li className={Styles.mb20}>
              Double click on 'Form' to configure it on the right panel
            </li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Message: </h4>
                <Typography>
                  The message configured here will be sent to the visitors when
                  'Form' action block is triggered along with the form fields
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>
                  Add fields to capture individual input:{" "}
                </h4>
                <ol className={Styles.howToUseListDecimal}>
                  <li>
                    <h4 className={Styles.mb20}>Name:</h4>
                    <Typography>
                      Enter the name of the field. This is what the visitors
                      will see
                    </Typography>
                  </li>
                  <li>
                    <h4 className={Styles.mb20}>Validation Method:</h4>
                    <Typography>
                      Select the validation method i.e text, name, email, phone
                      or custom
                    </Typography>
                  </li>
                  {/*<li>
                    <h4 className={Styles.mb20}>Define Variable:</h4>
                    <Typography>
                      Give a name to the variable in which the answers to this
                      field will be stored
                    </Typography>
                  </li>*/}
                </ol>
              </li>
              {/*<p className={Styles.hint}>
                You can click on <b>'+ Form Field' </b>to add more fields and
                repeat the above step to configure it.{" "}
              </p>*/}
            </ul>
          </ul>
          <Box className={Styles.importantText}>
            You can add maximum up to 5 fields You can have some fields as
            'Optional', so that the visitors can still move forward in the
            conversation if it is not filled
          </Box>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/Carousel`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>Carousel</Typography>
              </Box>
            </Box>
            <Box className={Styles.nextCont} onClick={() => navigate(`/Flow`)}>
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Flow</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should I use it?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should I use it?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FormUse;
