export const getPageNumbers = (currentPage, totalPages) => {
  const maxVisiblePages = 3;
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

  let startPage = currentPage - halfMaxVisiblePages;
  let endPage = currentPage + halfMaxVisiblePages;

  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(totalPages, maxVisiblePages);
  } else if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - maxVisiblePages + 1);
  }

  return Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );
};
