import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { baseURL } from "../../../../../axiosApi/axiosApi";
import { useNavigate, useParams } from "react-router-dom";
import Niyat_logo from "../../../../../assets/images/logo.jpg";
import BeatLoader from "react-spinners/BeatLoader";
import Styles from "../styles/otpCheckEmail.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import axios from "axios";

const OtpCheckEmail = () => {
  const [viewerLoading, setViewerLoading] = useState(false);
  const params = useParams();
  const userID = params.userID;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();
  const [otp, setOtp] = useState();
  const [errorDiv, setErrorDiv] = useState(false);
  const navigate = useNavigate();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };
  const handleOtpChange = async (e, type) => {
    const newEmail = e.target.value;
    setOtp(newEmail);

    setValue(type, newEmail);
    await trigger(type);
  };

  const navigatehandler = async (e) => {
    setViewerLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}/chatbot/KBot/CheckOTP?otp=${otp}&userid=${userID}`
      );
      // console.log(response);
      if (response.status === 200) {
        const { data } = response;
        if (data.message === "Success") {
          navigate(`/newPassword/${data.userId}`);
          setViewerLoading(false);
        } else {
          setErrorDiv(true);
          setViewerLoading(false);
        }
      }
    } catch (e) {
      console.log("ERROR");
    }
  };
  return (
    <Box className={Styles.mainOtpEmailCont}>
      <Box className={Styles.formCont}>
        <img src={Niyat_logo} alt="niyat-logo"></img>
        <Box className={Styles.words}>
          <Typography className={Styles.title}>Enter The Code</Typography>
          <Typography className={Styles.someTools}>
            Please enter the code that was sent to you in your email.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(navigatehandler)}>
          <FormControl isInvalid={errors.otpCode}>
            <Typography>Code</Typography>
            <Controller
              name="otpCode"
              control={control}
              defaultValue=""
              rules={{
                required: "This Field Is Required",
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || "It Mustn't contains only spaces",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => {
                    handleOtpChange(e, "otpCode");
                  }}
                  type="text"
                />
              )}
            />
            <Typography className={Styles.errorValidation}>
              {errors.otpCode && errors.otpCode.message}
            </Typography>
          </FormControl>

          <Box className={Styles.btnCont}>
            <Button
              variant="contained"
              type="submit"
              className={Styles.next}
              endIcon={<KeyboardDoubleArrowRightIcon />}
            >
              {viewerLoading ? (
                <BeatLoader color="black" loading={viewerLoading} size={7} />
              ) : (
                <Fragment>Next</Fragment>
              )}
            </Button>
          </Box>
        </form>
        {errorDiv && (
          <Box style={{ color: "red", marginTop: "10px" }}>
            The Code Is Wrong
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OtpCheckEmail;
