import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DesignButton from "../../../components/ui/button/DesignButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LeadGeneration from "../../../assets/templates/lead_generation_for_b2b.png";
import Appointment from "../../../assets/templates/appointment_booking.png";
import ShowCasing from "../../../assets/templates/property_showcasing.png";
import CustomerSupport from "../../../assets/templates/general_customer_support.png";
import PopertyTour from "../../../assets/templates/scheduling_a_property_tour.png";
import realEstate from "../../../assets/templates/lead_generation_for_real_estate_property.png";
import Ecommerce from "../../../assets/templates/ecommerce_customer_support.png";
import Brokers from "../../../assets/templates/lead_generation_for_brokers.png";
import InsurancePolicy from "../../../assets/templates/lead_generation_for_insurance_policy.png";
import Hospitals from "../../../assets/templates/appointment_booking_for_hospitals.png";
import Pizza from "../../../assets/templates/pizza_restaurant_chatbot.png";
import ResturantReservation from "../../../assets/templates/restaurant_reservation_chatbot.png";
import accOpening from "../../../assets/templates/account_opening_chatbot.png";
import appointmentBooking from "../../../assets/templates/appointment_booking.png";
import Branding from "../../../assets/templates/real_estate_branding.png";
import LeadGenerationForRealEstate from "../../../assets/templates/lead_generation_for_real_estate.png";
import { useNavigate, useParams } from "react-router-dom";

import Styles from "./ChatTemplates.module.css";

const ChatTemplates = () => {
  const params = useParams();
  const accountNumber = params.accNum;
  const [activeLink, setActiveLink] = useState(1);
  const [selectedType, setSelectedType] = useState(null);

  const navigate = useNavigate();

  const newChat = () => {
    navigate(`/design/${accountNumber}`);
    localStorage.removeItem("newChatIdDesign");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const templatesData = [
    {
      type: "Lead Generation",
      image: LeadGeneration,
      title: "Lead Generation for B2B",
      description:
        "Probe visitors coming to your channels by asking qualifying questions and generate quality SQLs.",
    },
    {
      type: "Lead Generation",
      image: Appointment,
      title: "Appointment Booking",
      description:
        "Collect the necessary details to book an appointment for your service through bots.",
    },
    {
      type: "Real Estate",
      image: ShowCasing,
      title: "Property Showcasing",
      description:
        "Showcase your properties and its common queries like amenities, floorplan, location etc.",
    },
    {
      type: "Customer Support",
      image: CustomerSupport,
      title: "General Customer Support",
      description:
        "Support your customers round the clock - Create service tickets, Ticket Status and answer FAQs",
    },
    {
      type: "Real Estate",
      image: PopertyTour,
      title: "Scheduling a Property Tour",
      description:
        "Make it easy for your future prospect to book a property tour by your virtual assistant",
    },
    {
      type: "Real Estate",
      image: realEstate,
      title: "Lead Generation for Real Estate Property",
      description:
        "Generate leads, provide information on your properties, and schedule site visit or book sales calls.",
    },
    {
      type: "Customer Support",
      image: Ecommerce,
      title: "Ecommerce Customer Support",
      description:
        "Handle the return/replacement requests for orders and provide human live chat.",
    },
    {
      type: "Real Estate",
      image: Brokers,
      title: "Lead Generation for Brokers",
      description:
        "Generate leads by prospects looking to buy, sell and invest in the real estate market.",
    },
    {
      type: "Insurance",
      image: InsurancePolicy,
      title: "Lead Generation for Insurance Policy",
      description:
        "Reduce operational expenses, improve customer experience without increasing overhead with a virtual manager.",
    },
    {
      type: "Healthcare",
      image: Hospitals,
      title: "Appointment Booking for Hospitals",
      description:
        "Schedule doctor appointments seamlessly with the required department effortlessly.",
    },
    {
      type: "Hsopitality",
      image: Pizza,
      title: "Pizza Restaurant Chatbot",
      description:
        "Enable everything from online ordering to increase orders while also generating franchisee leads to grow.",
    },
    {
      type: "Hsopitality",
      image: ResturantReservation,
      title: "Restaurant Reservation Chatbot",
      description:
        "Eliminate the time-extensive tasks of managing table reservations with restaurant bots.",
    },
    {
      type: "Banking & Finance",
      image: accOpening,
      title: "Account Opening Chatbot",
      description:
        "Automate the customer acquisition process by having your virtual assistant collect these details for you.",
    },
    {
      type: "Real Estate",
      image: appointmentBooking,
      title: "Scheduling Site Visit for Real Estate",
      description:
        "Schedule property tours with your visitors in minutes with our schedule site visit template.",
    },
    {
      type: "Real Estate",
      image: Branding,
      title: "Branding for Real Estate",
      description:
        "Create a long-lasting impression of your properties on your visitors with our branding template.",
    },
    {
      type: "Real Estate",
      image: LeadGenerationForRealEstate,
      title: "Lead Generation for Real Estate",
      description:
        "Generate leads with our easy to use and deploy real-estate lead generation template",
    },
    // Add more objects for other types as needed
  ];

  const handleLinkClick = (index) => {
    setActiveLink(index);
    switch (index) {
      case 1:
        setSelectedType(null);
        break;
      case 2:
        setSelectedType("Banking & Finance");
        break;
      case 3:
        setSelectedType("Customer Support");
        break;
      case 4:
        setSelectedType("Healthcare");
        break;
      case 5:
        setSelectedType("Hsopitality");
        break;
      case 6:
        setSelectedType("Insurance");
        break;
      case 7:
        setSelectedType("Lead Generation");
        break;
      case 8:
        setSelectedType("Real Estate");
        break;
      default:
        setSelectedType(null);
        break;
    }
  };

  const filteredCards = selectedType
    ? templatesData.filter((card) => card.type === selectedType)
    : templatesData;

  return (
    <Box style={{ height: "100%" }}>
      <Box className={Styles.container}>
        <Box className={Styles.industrySide}>
          <Box className={Styles.industryCont}>
            <Box>
              <Typography className={Styles.industryText}>Industry</Typography>
            </Box>
            <ul className={Styles.templatesCont}>
              <li
                onClick={() => handleLinkClick(1)}
                className={` ${activeLink === 1 ? Styles.linkActive : ""}`}
              >
                All Templates
              </li>
              <li
                onClick={() => handleLinkClick(2)}
                className={` ${activeLink === 2 ? Styles.linkActive : ""}`}
              >
                Banking & Finance
              </li>
              <li
                onClick={() => handleLinkClick(3)}
                className={` ${activeLink === 3 ? Styles.linkActive : ""}`}
              >
                Customer Support
              </li>
              <li
                onClick={() => handleLinkClick(4)}
                className={` ${activeLink === 4 ? Styles.linkActive : ""}`}
              >
                Healthcare
              </li>
              <li
                onClick={() => handleLinkClick(5)}
                className={` ${activeLink === 5 ? Styles.linkActive : ""}`}
              >
                Hsopitality
              </li>
              <li
                onClick={() => handleLinkClick(6)}
                className={` ${activeLink === 6 ? Styles.linkActive : ""}`}
              >
                Insurance
              </li>
              <li
                onClick={() => handleLinkClick(7)}
                className={` ${activeLink === 7 ? Styles.linkActive : ""}`}
              >
                Lead Generation
              </li>
              <li
                onClick={() => handleLinkClick(8)}
                className={` ${activeLink === 8 ? Styles.linkActive : ""}`}
              >
                Real Estate
              </li>
            </ul>
          </Box>
        </Box>
        <Box className={Styles.content}>
          <Box className={Styles.contentHeader}>
            <Box
              onClick={handleGoBack}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                cursor: "pointer",
                flex: 1,
              }}
            >
              <Box>
                <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
              </Box>
              <Typography className={Styles.titleHeader}>
                What do you want your chatbot to do?
              </Typography>
            </Box>
            <Box>
              <DesignButton text="Build from scratch" func={newChat} />
            </Box>
          </Box>
          <Box className={Styles.allTemplates}>
            <Box className={Styles.templatesCont}>
              <Box className={Styles.allCards}>
                {filteredCards.map((card, index) => (
                  <Box key={index} className={Styles.card}>
                    <Box className={Styles.cardHeader}>
                      <img src={card.image} alt={card.type} />
                    </Box>
                    <Box className={Styles.cardContent}>
                      <h4>{card.title}</h4>
                      <Typography className={Styles.ContentParagraph}>
                        {card.description}
                      </Typography>
                    </Box>
                    <Box className={Styles.cardFooter}>
                      <Button
                        className={Styles.designBtn}
                        onClick={() => navigate("/UnderConstraction")}
                        variant="contained"
                      >
                        Use this template
                      </Button>
                      <Button
                        className={Styles.previewBtn}
                        onClick={() => navigate("/UnderConstraction")}
                        variant="text"
                      >
                        Preview
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatTemplates;
