import { Box, Typography } from "@mui/material";
import Styles from "./Chats.module.css";

function FilterLabel({ children }) {
  return (
    <Box>
      <Typography className={Styles.chatLength}>{children}</Typography>
    </Box>
  );
}

export default FilterLabel;
