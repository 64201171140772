import React, { useState } from "react";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import { baseURL } from "../../../../axiosApi/axiosApi";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";

import DeleteButton from "../../../../components/ui/button/DeleteButton";

import Styles from "./DeleteChart.module.css";

const DeleteChart = ({ close, open, chatName, chatId, dataSet }) => {
  const [viewerLoading, setViewerLoading] = useState(false);

  const handleDelete = (Id) => {
    const accNum = localStorage.getItem("AC");
    const fetchData = async () => {
      try {
        setViewerLoading(true);
        const finalObj = {
          Chats: [{ ChatId: Id }],
          AccountNumber: accNum,
          IsAll: "0",
        };
        const response = await fetch(
          `${baseURL}/chatbot/KBot/DeleteChatByAccAndChatId?iddata=${JSON.stringify(
            finalObj
          )}`
        );
        if (response.status === 200) {
          setViewerLoading(false);
          dataSet((prevDataSet) =>
            prevDataSet.filter((el) => el.ChatId !== Id)
          );
          toast.success(`${chatName} chat has been deleted successfully!`);
          close();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };
  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth={true}>
      <DialogTitle className={Styles.dialogTitle}>Delete Bot?</DialogTitle>
      <DialogContent>
        <Typography className={Styles.textHead}>
          Are you sure you want to permanently delete <b>{chatName}</b>? This
          action cannot be undone.
        </Typography>
        <Divider className={Styles.line} />

        <DialogActions className={Styles.dialogBtns}>
          <DeleteButton
            func={close}
            text="Cancel"
            dis={viewerLoading}
            icon={false}
          ></DeleteButton>
          <Button
            variant="contained"
            className={Styles.deleteBtn}
            onClick={() => handleDelete(chatId)}
            sx={{ minWidth: "80px" }}
            color="error"
            disabled={viewerLoading}
          >
            {viewerLoading ? (
              <BeatLoader color="#36d7b7" loading={viewerLoading} size={7} />
            ) : (
              <>Delete</>
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteChart;
