import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import FilterLabel from "./FilterLabel";
import Chat from "./Chat";
import Pagination from "./Pagintaion";
import DeleteOptions from "./DeleteOptions";
import Styles from "./Chats.module.css";

const pageSize = 5;

const Chats = ({ filteredData, allData, dataSet }) => {
  const [selectedChats, setSelectedChats] = useState([]);
  // console.log(selectedChats);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredData]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / pageSize);

  // Slice data for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box className={Styles.lowerChatCont}>
      {allData?.length > 0 && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <FilterLabel>
            {filteredData?.length > 0
              ? `${filteredData?.length} bots created`
              : "No bots found"}
          </FilterLabel>
          <DeleteOptions selectedChats={selectedChats} dataSet={dataSet} />
        </Box>
      )}

      {currentData.map((chat) => (
        <Chat
          key={chat.ChatId}
          chat={chat}
          dataSet={dataSet}
          selectedChats={selectedChats}
          setSelectedChats={setSelectedChats}
        />
      ))}

      {/* Pagination Controls */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handlePageClick={handlePageClick}
      />
    </Box>
  );
};

export default Chats;
