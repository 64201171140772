import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import { Box } from "@mui/material";

import "./styles/slider.css";

const FlowModal = ({ allData }) => {
  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        nodeType={allData.nodeType}
        headerMessage={"Use this action block to connect two components."}
        toastHeader="Delay"
      />
    </Box>
  );
};

export default FlowModal;
