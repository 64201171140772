import { useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../../../axiosApi/axiosApi";
import { Box, Button } from "@mui/material";
import Styles from "./Chats.module.css";

const DeleteOptions = ({ selectedChats, dataSet }) => {
  // console.log(selectedChats);
  const [viewerLoading, setViewerLoading] = useState(false);
  const handleDelete = (deleteAll) => {
    const accNum = localStorage.getItem("AC");
    const fetchData = async () => {
      try {
        const Chats = selectedChats.map((chat) => ({ ChatId: chat.ChatId }));
        const finalObj = {
          Chats,
          AccountNumber: accNum,
          IsAll: deleteAll ? "1" : "0",
        };
        setViewerLoading(true);
        const response = await fetch(
          `${baseURL}/chatbot/KBot/DeleteChatByAccAndChatId?iddata=${JSON.stringify(
            finalObj
          )}`
        );
        if (response.status === 200) {
          setViewerLoading(false);
          if (deleteAll) {
            dataSet([]);
            toast.success(`All chats have been deleted successfully!`);
          } else {
            dataSet((prevDataSet) =>
              prevDataSet.filter(
                (el) => !Chats.some((chat) => chat.ChatId === el.ChatId)
              )
            );
            toast.success(`Chats have been deleted successfully!`);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };
  return (
    <Box className={Styles.deleteOptionsContainer}>
      <Button
        onClick={() => handleDelete(false)}
        className={Styles.deleteChat}
        disabled={selectedChats.length === 0 || viewerLoading}
      >
        Delete Seclected
      </Button>
      <Button
        onClick={() => handleDelete(true)}
        className={Styles.deleteChat}
        disabled={viewerLoading}
      >
        Delete All
      </Button>
    </Box>
  );
};

export default DeleteOptions;
