import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Box, Button } from "@mui/material";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import "../../Styles/chatbot.css";

const Buttons = ({ desc, btns, triggerNextStep }) => {
  const withoutQuotes = desc.replace(/'/g, "");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const transform = (node, index) => {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs &&
      node.attribs.href
    ) {
      if (node.attribs.href.startsWith("www.")) {
        node.attribs.href = `http://${node.attribs.href}`;
      }
    }
    return undefined;
  };

  const handleButtonClick = (el) => {
    setButtonsDisabled(true);
    triggerNextStep({ trigger: el.trigger });
  };

  return (
    <Box className="chatbot-container">
      <Box className="chatbot-shadow">
        <LogoContainer
          textHeader={ReactHtmlParser(withoutQuotes, { transform })}
        />
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
            gap: "10px",
            width: "80%",
            marginBottom: "20px",
          }}
        >
          {btns?.map((el, index) => (
            <Button
              key={index}
              className="Carousel-view-btns"
              onClick={() => handleButtonClick(el)}
              disabled={buttonsDisabled}
            >
              {el.value}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Buttons;
