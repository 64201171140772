import RoutesProvider from "./RoutesProvider";
import Navigations from "../navigations/Navigations";
import { Fragment } from "react";
import ResponsiveMessage from "../features/responsive/ResponsiveMessage";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppProvider = () => {
  return (
    <Fragment>
      <ResponsiveMessage />
      <RoutesProvider>
        <Navigations />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
      </RoutesProvider>
    </Fragment>
  );
};

export default AppProvider;
